jQuery(function ($) {

$('.btn-delete').click(function (e) {
        e.preventDefault();
        confirmDeletion(e.target);
    });



});


//functions lib

function formatMoney(price_in_cents) {

    if (isNaN(price_in_cents)) {
        return '---';
    }

    if (price_in_cents.length < 2) {
        return '0,0' + price_in_cents + " €";
    }
    if (price_in_cents.length == 2) {
        return '0,' + price_in_cents + " €";
    }

    var return_string = '';
    var euros = price_in_cents.substr(0, price_in_cents.length - 2);
    var cents = price_in_cents.substr(price_in_cents.length - 2, 2);

    var j = 1;
    for (var i = euros.length - 1; i >= 0; i--) {
        if (j % 3 == 0 && j < euros.length) {
            return_string = '.' + euros[i] + return_string;
        } else {
            return_string = euros[i] + return_string;
        }
        j++;
    }

    return_string += ',' + cents + ' €';

    return return_string;

}

function confirmDeletion(target) {

    bootbox.confirm("Wirklich für immer löschen?", function (result) {
        if (result){
            $(target).closest("form").submit();
        }
    });

}
